.dropdownBtn {
    background: transparent;
    border: none;
}

.searchContainer:hover {
    background-color: inherit!important;
}

.fieldSvg {
    path {
        fill: #b5b5c3!important;
    }
}

.input {
    margin: 0 5px;
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .notificationSvg {
            path {
                fill: rgba($text-secondary, .8);

                @media (max-width: 959px) {
                    fill: $primary;
                }
            }
        }
    }
}

@import './../../../../../global/style/app_themes.scss'