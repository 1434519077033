.labelCentered {
	color: #00b1eb;
	font-weight: 600;
	font-size: 20px;
	margin-top: 35px;
	margin-bottom: 20px;
	text-align: center;
	width: 100%;
	display: block;
}
