@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        .list {
                .title {
                    text-align:left;
                }
            }

            .menuItemActive {
                &::after {
                    left: 15px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }

                .activeCurve {
                    right: 0;
                }
            }
        }
    }

@import './../../../../global/style/app_themes.scss'