.itemLev2 {
    padding: 0 30px 0 25px;
}

.itemLev3 {
    padding: 0 40px 0 25px;
}

.itemLev4 {
    padding: 0 50px 0 25px;
}