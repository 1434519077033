/* width */
::-webkit-scrollbar {
    width: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    // background: $primary; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #5e5d5daf; 
}
