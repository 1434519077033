.root {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100% !important;
    position: relative;

    .content {
        flex-grow: 1;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        max-width: 100%;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        :global(.mainContainer) {
            background-color: lighten($primary, 95%)!important;
            background-image: none!important;
            background-size: cover!important;
            // overflow: hidden!important;
            // height: 100vh!important;
        }
    }
}

@import './../global/style/app_themes.scss'

