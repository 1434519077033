@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        .list {
            .title {
                text-align: right;
            }
        }

        .menuItemActive {

            &::after {
                right: 15px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                padding: 20px;
            }

            .activeCurve {
                left: 0;
            }
        }
    }
}

@import './../../../../global/style/app_themes.scss'
