.dropdownBtn {
    background: transparent;
    border: none;
}

.tasksPageBtn {
    justify-content: center!important;
    height: 36px;
}

.dropdown {
    :nth-child(3) {
        min-width: 300px;
    }

    .newTask {
        padding: .5rem 2rem!important;
    }
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .tasksSvg {
            margin-top: 5px;
            path {
                fill: rgba($text-secondary, .8);

                @media (max-width: 959px) {
                    fill: $primary;
                }
            }
        }

        .userTasks {
            background: $primary!important;
            color: $text-secondary!important;
            margin-top: -8px!important;
            margin-bottom: 1rem!important;
            height: 80px;
        }

    }
}

@import './../../../../../global/style/app_themes.scss'