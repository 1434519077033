@include appTheme(
	// $theme-name
	theme1,
	// $primary
	#151713,
	// $secondary
	#00b1eb,
	// $text-primary
	#353733,
	// $text-secondary
	#ffffff,
	// $default-svg
	#ffffff4d
);

@include appTheme(
	// $theme-name
	theme2,
	// $primary
	#001551,
	// $secondary
	#ffcc00,
	// $text-primary
	#001551,
	// $text-secondary
	#ffffff,
	// $default-svg
	#ffffff4d
);

@include appTheme(
	// $theme-name
	theme3,
	// $primary
	#000000,
	// $secondary
	#ff008c,
	// $text-primary
	#000000,
	// $text-secondary
	#ffffff,
	// $default-svg
	#ffffff4d
);

@include appTheme(
	// $theme-name
	theme4,
	// $primary
	#262626,
	// $secondary
	#0099cc,
	// $text-primary
	#262626,
	// $text-secondary
	#ffffff,
	// $default-svg
	#ffffff4d
);

@include appTheme(
	// $theme-name
	// STRIP THEME
	theme5,
	// $primary
	#262626,
	// $secondary
	#00b1eb,
	// $text-primary
	#262626,
	// $text-secondary
	#ffffff,
	// $default-svg
	#ffffff4d
);
