.altWrap {
	background-color: #fafafa;
	border-radius: 20px;
	padding: 12px;
	margin-top: 20px;
}

.attachTitleWithLink {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.attachTitleWithoutLink {
	height: 35px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.attacHeader {
	height: 45px;
}

.inlineList {
	max-width: none !important;
	background-color: transparent !important;

	> nav {
		display: inline-block;
		width: 100%;

		> li {
			float: right;
			width: 33%;
			background-color: #fff;
			margin-bottom: 0.5%;

			& > div:first-child {
				height: 90px !important;

				& > div:last-child {
					span {
						width: 80%;
					}
				}
			}
		}

		> li:not(:nth-child(3n)) {
			border-left: 2px solid #ddd;
		}

		> li:last-child {
			border-left: none !important;
		}
	}
}
