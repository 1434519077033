.contentContainer {
    position: relative;
    min-height: 550px;
    display: flex;
    align-items: center;
    padding-bottom: 3rem;

    .langContainer {
        display: flex;
        justify-content: flex-end;
        margin: 0 8.333333% .5rem;

        @media(max-width: 991px) {
            margin: 0 0 .5rem;
        }
    }

    .whiteContainer {
        background: white;
        border-radius: 20px;
        margin: 0 8.333333%;
        box-shadow: rgba(0,0,0,.3) 0px 20px 20px 10px;

        @media(max-width: 991px) {
            margin: 0;
        }

        @media(max-width: 767px) {
            margin-top: .5rem;
        }

        .logo {
            max-width: 200px;
            object-fit: contain;
        }
    }
}

.videoWrap {
    height: 100%;
    width: 100%;
    filter: contrast(1.4) saturate(1.8) sepia(0.6);
    overflow: hidden;
}

@mixin loginTheme($theme-name, $primary, $secondary, $info, $bgOverlay, $bg) {

    :global(.#{$theme-name}) {

        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
    }
}

@import './../../global/style/login_themes.scss'

    
