.dropdownBtn {
    background: transparent;
    border: none;
}

.notificationPageBtn {
    justify-content: center!important;
    height: 36px;
}

.dropdown {
    :nth-child(3) {
        min-width: 300px;
    }

    .menuItem {
        padding: .5rem 2rem!important;
    }
}
.unReadNotification {
    background-color: rgba(0, 0, 0, 0.04);
    animation: notification-pulse 3.5s ease-out infinite;
}

@keyframes notification-pulse {
    0% {-webkit-transform: scale(0.9);}
    50% {-webkit-transform: scale(0.85);}
    75% {-webkit-transform: scale(0.9);}
    100% {-webkit-transform: scale(1);}
}
@keyframes animation-pulse {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    60% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    65% {opacity: 1;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .notificationSvg {
            margin-top: 5px;
            path {
                fill: rgba($text-secondary, .8);

                @media (max-width: 959px) {
                    fill: $primary;
                }
            }
        }

        .userNotification {
            background: $primary!important;
            color: $text-secondary!important;
            margin-top: -8px!important;
            margin-bottom: 1rem!important;
            height: 80px;
        }

        .notificationBadge {
            :nth-child(2) {
                background: #dc3545;
                color: $text-secondary;
                top: 8px;
                right: 5px;
                min-width: 15px;
                height: 15px;
                padding: 0 4px;
                font-size: 10px;
            }
        }

        .pluse {
            position: relative;
            cursor: pointer;
        
            .pulseRing {
                display: block;
                border-radius: 40px;
                height: 40px;
                width: 40px;
                position: absolute;
                animation: animation-pulse 3.5s ease-out;
                animation-iteration-count: infinite;
                opacity: 0;
                border-width: 3px;
                border-style: solid;
                border-color: $text-secondary;
                top: -6px;
                right: -5px;

                @media (max-width: 959px) {
                    border-color: $primary;
                }
            }
        }
    }
}

@import './../../../../../global/style/app_themes.scss'