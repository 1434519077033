@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .footer {
            text-align: center;
            font-size: 11px;
            margin-bottom: 0;
            color: lighten($text-primary, 30%);
            background-color: #fff;
            box-shadow: 0px 0px 3px rgba(0,0,0, 0.05);

            .brandName {
                font-weight: bold;
            }
        }
    }
}


@import './../../../global/style/app_themes.scss'
    

    
