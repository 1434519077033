:global {
	.upload-btn-wrapper {
		position: relative;

		input[type="file"] {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 99;
		}
	}

	.dx-selection .testTemplateBtn {
		background-color: #e6e6e6;
	}

	.testTemplateBtn {
		background-color: #fff;
		border: none;
		padding: 12px;
		cursor: pointer;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 30px;
	}

	.testTemplateBtn:hover {
		background-color: #333;
		border-radius: 50%;
	}

	.testTemplateBtn > * {
		pointer-events: none;
	}

	.testTemplateBtn path {
		fill: #757575;
	}

	.testTemplateBtn:hover path {
		fill: #fff;
	}
}
