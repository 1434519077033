.logoImg {
	width: 100%;
	height: 4.5rem;
	margin-top: 1rem;
}

.test {
	background-color: darkgrey;
}

@mixin appTheme(
	$theme-name,
	$primary,
	$secondary,
	$text-primary,
	$text-secondary,
	$default-svg
) {
	:global(.#{$theme-name}) {
		.toggleBtn {
			cursor: pointer;
			padding: 5px;

			path {
				fill: $secondary;
			}
		}

		:global(.MuiDrawer-paperAnchorLeft),
		:global(.MuiDrawer-paperAnchorRight) {
			background-color: $primary !important;
			background-image: url(../../../imagesTemp/STRIP-LogoBGVer.png);
			// background-image: url(../../../images/ShjMunUniPortal-LogoBGVer.svg);
			background-position: bottom center;
			background-repeat: no-repeat;
			background-size: 70%;
			color: $text-secondary !important;
			text-align: center;
			font-weight: 700;
		}

		:global(.lev1BtnActive) {
			background: darken($primary, 90%);
		}

		:global(.lev1Btn) {
			padding-left: 16px !important;
			padding-right: 16px !important;
			background-color: $primary !important;
		}

		:global(.collapseLev1),
		:global(.collapseLev2),
		:global(.collapseLev3) {
			li {
				background: darken($primary, 90%);
				color: darken($text-secondary, 10%);

				&:hover {
					color: $text-secondary;

					span {
						span {
							background: $text-secondary;
						}
					}
				}
			}
		}

		:global(.open-collapseLev1),
		:global(.open-collapseLev2),
		:global(.open-collapseLev3) {
			background: darken($primary, 3%);

			a {
				height: 40px;
				text-decoration: none;
				color: darken($text-secondary, 10%);

				span {
					white-space: pre-wrap;
					text-align: start;
					font-size: 13px;
				}

				&:hover {
					color: $text-secondary;
				}
			}
		}
	}
}

@import "./../../../global/style/app_themes.scss";
