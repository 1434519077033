.passwordIcon {
    width: 1.3rem;
}

.keepMeSigned {
    font-size: 14px;
    color: grey;
    // width: 60px;
    padding: 3px;

    &:hover {
        color: grey;
        text-decoration: none;
    }

    span {
        font-size: 13px;
    }

    svg {
        path {
            fill: #949494!important;
        }
    }
    & + span {
        font-size: 11px;
    }
}

@mixin loginTheme($theme-name, $primary, $secondary, $info, $bgOverlay, $bg) {

    :global(.#{$theme-name}) {

        .signInBtn {
            min-width: 114px!important;
            background: $primary;
            color: #fff;
            border-radius: 30px!important;
            width: 100%;
            text-transform: capitalize!important;
            text-decoration: none;
            padding: 0.75rem 1.5rem;

            &:hover {
                background: darken($primary, 90%);
            }
        }
    }
}

@import './../../global/style/login_themes.scss'