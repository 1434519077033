h3 {
	color: #00b1eb;
	text-transform: capitalize;
}
.stepsNav {
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.navLink {
	position: relative;
	color: #999;
	padding-top: 1.5rem !important;
	font-size: 1rem;

	&:hover {
		color: #999;
	}

	&:after {
		content: "";
		display: block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: #151713;
		opacity: 0.8;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: #151713;
		opacity: 0.4;
		position: absolute;
		left: 0;
		right: 0;
		top: 0px;
	}
}

.navLink.active {
	background-color: transparent !important;
	color: #00b1eb !important;
	font-size: 1.2rem;

	&:after {
		width: 20px;
		height: 20px;
		background: #00b1eb;
		border: 3px solid #fff;
		box-shadow: #00b1eb 0px 0px 0px 5px;
		opacity: 1;
	}

	&:before {
		background: #00b1eb;
		opacity: 1;
	}
}
