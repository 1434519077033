.close-collapseLev1,
.close-collapseLev2,
.close-collapseLev3 {
	ul {
		display: none !important;
	}

	max-height: 0;
	visibility: hidden;
}

.open-collapseLev1,
.open-collapseLev2,
.open-collapseLev3 {
	// max-height: 500px;
	visibility: visible;
	// transition: max-height 0.5s ease-out;
}

.MuiTouchRipple-root {
	visibility: hidden;
}
