@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .topbarUl {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: $primary;
        
            @media (max-width: 959px) {
                justify-content: center;
                align-items: center;
                height: 100%;
                padding-top: 4rem;
            }
        
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 44px;
                height: 44px;
        
                &:last-child {
                    padding: 0;
                    width: auto;
                }
                path {
                fill: lighten($text-primary, 30%);
                }

                &:hover {
                    background: lighten($primary, 80%);
                    border-radius: 5px;
                    color:$secondary;
                    path {
                        fill: $secondary;

                        @media (max-width: 959px) {
                            fill: $primary;
                        }
                    }

                    @media (max-width: 959px) {
                        background: darken($text-secondary, 10%);
                    }

                }
            }
        }

        .helpSvg {
            cursor: pointer;
            
            path {
                fill: rgba($text-secondary, .8);

                @media (max-width: 959px) {
                    fill: $primary;
                }
            }
        }
    }
}

@import './../../../../global/style/app_themes.scss'
