@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {

    :global(.#{$theme-name}) {

        .depJobTabs {
            .tabsPills {
                padding: 0 45px; 
                margin-bottom: -10px; 
                font-weight: bold;

                .tabsItems {
                    border: 2px solid #f0f0f0;
                    border-bottom: 0;

                    .tabsLink {
                        color: $secondary;
                        font-size: 15px;
                        padding: 0.6rem;

                        &:global(.nav-link.active) {
                            background-color: #fff!important;
                        }
                    }
                }
            }
            
        }
    }
}

@import './../../../global/style/app_themes.scss'