.languageBtn {
    background: transparent;
    border: none;
    color: #82867d;
    font-size: 16px;
}

@mixin loginTheme($theme-name, $primary, $secondary, $info, $bgOverlay, $bg) {

    :global(.#{$theme-name}) {

        .loginLanguageBtn {
            width: 40px;
            background: transparent;
            border-radius: 20px;
            height: 40px;
            border: none;
            background-color: $secondary;
            color: lighten($primary, 90%);
            font-weight: 700;

            
        }
    }
}

@import './../../../global/style/login_themes.scss'