@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        .list {
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
            margin-top: 1rem !important;

            .title {
                color: lighten($secondary, 10%);
                font-size: 13px;
                padding: 20px 25px 10px !important;
                display: block;
                text-transform: uppercase;
                text-align: start;
                font-weight: 400;

                &.dots {
                    text-align: center;
                }
            }

            .menuItem {
                .icon {
                    width: 35px;
                    min-width: unset;

                    path {
                        // fill: $default-svg;
                        fill: #fff;
                    }
                }

                .itemText {
                    color: darken($text-secondary, 10%);
                    text-align: start;
                    text-transform: capitalize;

                    svg {
                        path {
                            // fill: $default-svg
                            fill: #fff;
                        }
                    }
                }

                .hideItemText {
                    visibility: hidden;
                }

                .activeCurve {
                    display: none;
                }

                // &:hover {
                //     background: darken($primary, 90%);

                //     .icon {
                //         path {
                //             fill: $secondary
                //         }
                //     }

                //     .itemText {
                //         color: $text-secondary;
                //     }
                // }

                &.menuItemActive {
                    // background: transparent;
                    background: $secondary;

                    &::after {
                        content: ' ';
                        background: $secondary;
                        position: absolute;
                        width: 250px;
                        height: 38px;

                        @media (max-width: 959px) {
                            width: 260px;
                        }
                    }

                    .activeCurve {
                        z-index: 99;
                        position: absolute;
                        display: block;

                        &.topCurve {
                            top: -13px;
                        }

                        &.bottomCurve {
                            bottom: -11px;
                        }

                        svg {
                            width: 1rem;

                            path {
                                fill: $secondary;
                                transform: rotate(0deg) !important;
                            }
                        }
                    }

                    .icon {
                        z-index: 9;

                        path {
                            fill: $text-secondary
                        }
                    }

                    .itemText {
                        z-index: 9;
                        color: $text-secondary;
                    }
                }

                .icon {
                    span {
                        margin: auto;
                    }
                }
            }

            ul {
                padding: 0;
                list-style: none;

                li, li:hover {
                    height: 40px;
                    cursor: pointer;
                    padding: 0 20px;
                    color: #cdb47c;
                }
            }
        }

        // :global(.collapseLev2 ) {
        //     padding: 0px 12%!important;
        // }

        :global(.collapseLev2.open-collapseLev2) {
            padding-bottom: 0.5rem!important;
            padding-top: 0.5rem!important;
        }
    }
}

@import './../../../../global/style/app_themes.scss';

