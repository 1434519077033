h3 {
	color: #00b1eb;
	text-transform: capitalize;
}
.stepsNav {
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.navLink {
	position: relative;
	color: #999;
	padding-top: 1.5rem !important;
	font-size: 1rem;

	&:hover {
		color: #999;
	}

	&:after {
		content: "";
		display: block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: #151713;
		opacity: 0.8;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: #151713;
		opacity: 0.4;
		position: absolute;
		left: 0;
		right: 0;
		top: 0px;
	}
}

.navLink.active {
	background-color: transparent !important;
	color: #00b1eb !important;
	font-size: 1.2rem;

	&:after {
		width: 20px;
		height: 20px;
		background: #00b1eb;
		border: 3px solid #fff;
		box-shadow: #00b1eb 0px 0px 0px 5px;
		opacity: 1;
	}

	&:before {
		background: #00b1eb;
		opacity: 1;
	}
}

:global {
	.MuiButtonBase-root.grid-upload-button {
		position: relative;
		margin: 0 auto;
		display: block;
		&:hover {
			background-color: #333;
			color: #fff;
		}

		input[type="file"] {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 99;
		}
	}

	.upload-btn-wrapper {
		position: relative;
		input[type="file"] {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 99;
		}
	}

	.MuiListItemSecondaryAction-root {
		button {
			&:hover {
				background-color: #333;
				color: #fff;
			}
		}
	}
	.dx-row-focused {
		.MuiListItemSecondaryAction-root {
			button {
				background-color: #fff;
				&:hover {
					color: #333;
				}
			}
		}

		.MuiButtonBase-root.grid-upload-button {
			background-color: #fff;
			&:hover {
				color: #333;
			}
		}
	}

	.grid-list-item {
		.MuiListItemText-secondary {
			color: #00b1eb;
		}
	}

	.dx-selection .testTemplateBtn {
		background-color: #e6e6e6;
	}

	.testTemplateBtn {
		background-color: #fff;
		border: none;
		padding: 12px;
		cursor: pointer;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 30px;
		border-radius: 50%;
	}

	.testTemplateBtn:hover {
		background-color: #333;
	}

	.testTemplateBtn > * {
		pointer-events: none;
	}

	.testTemplateBtn path {
		fill: #757575;
	}

	.testTemplateBtn:hover path {
		fill: #fff;
	}
}

.dragDemo {
	background-color: #ccc;
	border: 1px solid #333;
	padding: 10px;
	width: 100%;
}
