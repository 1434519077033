.dashboardListContainer{
    // overflow-y: overlay;
    overflow-x: hidden;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translate3d(0,0,0);
    padding: 7rem 2rem 0 2rem;
}