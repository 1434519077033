.itemLev2 {
    padding: 0 25px 0 30px;
}

.itemLev3 {
    padding: 0 25px 0 40px;
}

.itemLev4 {
    padding: 0 25px 0 50px;
}