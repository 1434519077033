h3 {
	color: #00b1eb!important;
	text-transform: capitalize;
}
.stepsNav {
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-left: auto;
	margin-right: auto;
}

.navLink {
	position: relative;
	color: #999;
	padding-top: 1.5rem !important;
	font-size: 1rem;

	&:hover {
		color: #999;
	}

	&:after {
		content: "";
		display: block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: #151713;
		opacity: 0.8;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -50%);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: #151713;
		opacity: 0.4;
		position: absolute;
		left: 0;
		right: 0;
		top: 0px;
	}
}

.navLink.active {
	background-color: transparent !important;
	color: #00b1eb !important;
	font-size: 1.2rem;

	&:after {
		width: 20px;
		height: 20px;
		background: #00b1eb;
		border: 3px solid #fff;
		box-shadow: #00b1eb 0px 0px 0px 5px;
		opacity: 1;
	}

	&:before {
		background: #00b1eb;
		opacity: 1;
	}
}

.actionsWrap {
	position: fixed;
	bottom: 26px;
	z-index: 1;
	background-color: rgba($color: #f3f4f2, $alpha: 1);
	left: 15px;
	border-top-right-radius: 10px;
	// border-bottom-right-radius: 20px;
	// transform: translateX(50%);
	padding: 10px 8px;
}

.tasksWrap,
.altWrap {
	background-color: #fafafa;
	border-radius: 20px;
	padding: 12px;
	margin-top: 20px;
}

.tasksWrap {
	padding-bottom: 30px;
}

.taskWrap {
	transition: all 0.4s ease-in-out;
	&:hover {
		box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.3);
		border-radius: 8px;
		margin-left: -7px;
		margin-right: -7px;
	}
}

.stepWrap {
	label {
		font-size: 11px;
		color: #00b1eb;
		& ~ p {
			border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.42);
			padding-bottom: 7px;
			cursor: default;
		}
	}
}

.accordionWrap {
	border-radius: 24px !important;
	&:last-child {
		border-radius: 25px;
	}
	&:before {
		display: none;
	}
}

.accordionDetail {
	display: block !important;
}
