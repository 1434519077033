.loginForm {

    @media (max-width: 991px) {
        margin-bottom: 2rem!important;
    }

    @media (max-width: 767px) {
        margin-bottom: 0!important;
    }
}

.followUS {
    display: block;
    text-align: end;
    font-size: 12px;
    color: #a7a7a7;
}

.help {
    display: block;

    @media (min-width: 1200px) {
        margin-top: 4.5rem!important;
        margin-bottom: 2rem!important;
    }

    @media (min-width: 991px) {
        margin-top: 2.5rem;
        margin-bottom: 2rem!important;
    }

    @media (max-width: 991px) {
        display: none;
    }

    svg {
        width: 1rem;
        path {
            fill: #cccccc!important;
        }
    }

    span, a {
        color: #cccccc;
        font-size: 12px;
        margin: 0 5px;
    }

    .dot {
        display: inline-block;
        width: 3px;
        height: 3px;
        background: #cccccc;
    }
}

@mixin loginTheme($theme-name, $primary, $secondary, $info, $bgOverlay, $bg) {

    :global(.#{$theme-name}) {

        .socialMediaCont {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: -5px;
        
            .socialLink {
                margin: 0 4px;
                width: 2rem;
        
                svg {
                    width: 100%;
                    height: 100%;
                    
                    path {
                        fill: #cccccc;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: $info;
                        }
                    }
                }
            }
            
        }

        .titleContainer {
            padding: 24px 0 10px;
            .welcome {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 8px;
                color: $info;
            }
        
            .login2YourAcc {
                margin-top: -11px;
                color: $info;
            }
        }
    }
}

@import './../../global/style/login_themes.scss'

