// Sizes
.svg-xs {
    svg {
        height: .5rem;
        width: .5rem;
    }
}

.svg-sm {
    svg {
        height: 1rem;
        width: 1rem;
    }
}

.svg-md {
    svg {
        height: 1.7rem;
        width: 1.7rem;
    }
}

.svg-lg {
    svg {
        height: 2rem;
        width: 2rem;
    }
}