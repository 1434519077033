.contentContainer {
	position: relative;
	min-height: 550px;
	display: flex;
	align-items: center;
	padding-bottom: 3rem;

	.langContainer {
		display: flex;
		justify-content: flex-end;
		margin: 0 8.333333% 0.5rem;

		@media (max-width: 991px) {
			margin: 0 0 0.5rem;
		}
	}

	.whiteContainer {
		background: white;
		border-radius: 20px;
		margin: 0 8.333333%;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 20px 10px;

		@media (max-width: 991px) {
			margin: 0;
		}

		@media (max-width: 767px) {
			margin-top: 0.5rem;
		}

		.logo {
			max-width: 200px;
			object-fit: contain;
		}
	}
}

.videoWrap {
	height: 100%;
	width: 100%;
	filter: contrast(1.4) saturate(1.8) sepia(0.6);
	overflow: hidden;
}

.ndaHeader {
	max-width: 100%;
	height: auto;
}

.ngaTitle {
	width: 100%;
	font-size: 28px;
	// font-weight: 700;
	margin-bottom: 8px;
	margin-top: 24px;
	color: #2c2276;
}

.ndaInfo {
	border-bottom: 1px solid #2c2276;
	margin-bottom: 10px;
}

.ndaBoldHighlight {
	font-weight: 700;
	color: #2c2276;
}

.ndaConfirm {
	display: flex;
	align-items: center;
	margin-inline-end: 18px;
	label {
		font-size: 18px;
		color: #2c2276;
		margin-inline-start: 12px;
		margin-block: 0;
	}
}

@mixin loginTheme($theme-name, $primary, $secondary, $info, $bgOverlay, $bg) {
	:global(.#{$theme-name}) {
		background-image: $bgOverlay, $bg;
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 100vh;
	}
}

@import "./../../global/style/login_themes.scss";
