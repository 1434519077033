@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        .bulletDot {
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: darken($text-secondary, 10%);
            margin: 0 6px;
        }

        .itemLev2, .itemLev3 {
            svg {
                path {
                    fill: #fff;
                    // fill: $default-svg!important;
                }
            }
        }

        .icon {
            width: 25px;
            min-width: unset;

            svg {
                width: 1.4rem!important;
                height: 1.4rem!important;
            }

            path {
                // fill: $default-svg;
                fill: #fff;
            }
        }

        .activeParent {
            font-weight: bold!important;
            // color: $text-secondary!important;
            // color: #BAA167!important;
            background: #BAA167!important;
            color: #fff!important;

            .bulletDot {
                background: $text-secondary!important;
            }
        }

        .activeLink {
            font-weight: bold!important;
            color: lighten($secondary, 20%)!important;

            .bulletDot {
                background: lighten($secondary, 20%)!important;
            }
        }
    }
}

@import './../../../../../global/style/app_themes.scss'