.headerCon{
    background-color: #fff;
    box-shadow: 30px 0px 30px rgba(0,0,0,.1);
    z-index: 9;
    position: fixed;
    transition: width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 100vw !important;
    // width: calc(100vw - 73px)
}

.bar {
    display: block;
    background-color: #f7f2f2;
    opacity: .3;
    height: 3px;
    border-radius: 3px;
    transition: all .3s;
}

.customMenuIcon {
    .bar {
        width: 24px;
        margin: 5px 8px;
    }
}

.topbarIcon {
    path {
        fill: #f7f2f2;
        opacity: .3;
    }
}

.sideMenuIcon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    .bar {
        &:nth-child(1) {
            width: 12px;
            margin: 0 2px;
        }
        &:nth-child(2) {
            width: 24px;
            margin: 5px 2px;
        }
        &:nth-child(3) {
            width: 18px;
            margin: 0 2px;
        }
    }
}

.logoImg {
    max-width: 100%;
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .customMenuIcon:hover, .sideMenuIcon:hover {
            .bar {
                background-color: $secondary;
                opacity: 1;
            }
        }

        .topbarIcon:hover {
            path {
                fill: $secondary;
                opacity: 1;
            }
        }

        :global(.MuiAppBar-colorPrimary) {
            background-color: $primary!important;
        }
    }
}

@import './../../../global/style/app_themes.scss'
